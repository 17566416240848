import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import {bindActionCreators} from 'redux';
import {prefixActions} from '@automationanywhere/rio-components';

import {selectT} from '../../../../store/selectors/i18n';
import * as pagesActions from '../../../../store/actions/pages';
import * as migrationsActions from '../../../../store/actions/migrations';
import {
    selectAuditDataMigrationResultsFields,
} from '../../../../store/selectors/migrations';
import {selectCanViewMigrations} from '../../../../store/selectors/access';

import MigrationAuditDataViewPage from './MigrationAuditDataViewPage.jsx';

export const MigrationAuditDataView = connect(
    () => {
        const selectMigrationId = (state, props) => props.params.migrationId;
        const selectStepProps = createSelector(
            selectT,
            (t) => ({t}),
        );

        const buildSteps = (props = {}) => {
            const {t = (s) => s} = props;

            return [{
                id: 'metadata',
                label: t('migrations:wizard-result-step-title'),
            }, {
                id: 'results',
                label: t('migrations:wizard-audit-data-migration-results'),
            }];
        };

        const selectSteps = createSelector(
            selectStepProps,
            (props) => buildSteps(props)
        );

        return (state, props) => ({
            t: selectT(state),
            migrationId: selectMigrationId(state, props),
            migration: state.migrations.migration,
            steps: selectSteps(state, props),
            resultsFields: selectAuditDataMigrationResultsFields(state),
            canViewMigration: selectCanViewMigrations(state),
        });
    },
    (dispatch) => ({
        actions: bindActionCreators({
            ...prefixActions('pages', pagesActions),
            ...prefixActions('migrations', migrationsActions),
        }, dispatch),
    })
)(MigrationAuditDataViewPage);
