import {createSelector} from 'reselect';

import {selectT} from '../../store/selectors/i18n';
import {createNameDataField} from '../../components/common/Name';
import {
    createStringDataField,
    createDateDataField,
    createObjectAttributeArrayDataField,
    createNumberDataField,
} from '../../util/format';
import {
    createUserDataField,
    createUserStatusDataField,
} from '../../components/resources/users';
import {
    LICENSE_CATEGORY_TYPE_USER,
    LICENSE_CATEGORY_TYPE_DOCUMENT_AUTOMATION,
    LICENSE_CATEGORY_TYPE_DOCUMENT_AUTOMATION_CLASSIFIER,
    LICENSE_CATEGORY_TYPE_API_AS_SERVICE,
} from '../../store/constants/licenses';

export const createCurrentControlRoomFieldsSelector = (selectGetHref) => createSelector(
    selectT,
    selectGetHref,
    (t, getHref) => {
        const fields = [
            createNameDataField('username', t('label-username'), t, getHref),
            createStringDataField('firstName', t('label-first-name'), t),
            createStringDataField('lastName', t('label-last-name'), t),
            createDateDataField('lastLoginTime', t('label-last-login-on'), t, {sortable: true, filterable: false}),
            createUserStatusDataField('disabled', t('label-user-status'), t),
            createObjectAttributeArrayDataField('roles', t('resource-role-label_other'), 'name', t),
            createDateDataField('updatedOn', t('field-last-modified'), t, {sortable: true, filterable: false}),
            createUserDataField('updatedBy', t('field-modified-by'), t, {includeDeletion: true, isSystemZero: true}),
        ];
        return fields;
    }
);

export const createOtherControlRoomFieldsSelector = (selectGetHref, licenseType) => createSelector(
    selectT,
    selectGetHref,
    licenseType,
    (t, getHref, licenseType) => {
        const fields = [
            createNameDataField('accessUrl', t('licenseDetails:controlroom-name-label'), t, getHref),
            createNumberDataField('consumedCount',
                licenseType === LICENSE_CATEGORY_TYPE_USER ? t('licenseDetails:license-allocated-label') :
                licenseType === LICENSE_CATEGORY_TYPE_DOCUMENT_AUTOMATION || licenseType === LICENSE_CATEGORY_TYPE_DOCUMENT_AUTOMATION_CLASSIFIER ? t('licenseDetails:pages-allocated-label') :
                licenseType === LICENSE_CATEGORY_TYPE_API_AS_SERVICE ? t('licenseDetails:number-of-executions-label') : '', t),
        ];
        return fields;
    }
);
