
import React from 'react';
import {DataField, DataType} from '@automationanywhere/rio-components';

import SelfConnectionStatusTypeField from './SelfConnectionStatusTypeField.jsx';

const OAUTH_TOKEN_STATUS_UNKNOWN = 'OAUTH_TOKEN_STATUS_UNKNOWN';
const OAUTH_TOKEN_STATUS_ACTIVE = 'OAUTH_TOKEN_STATUS_ACTIVE';
const OAUTH_TOKEN_STATUS_EXPIRED = 'OAUTH_TOKEN_STATUS_EXPIRED';

const getStatusLabel = (t, status) => {
    switch (status) {
        case OAUTH_TOKEN_STATUS_UNKNOWN:
            return t('oauthConnections:token-status-unknown');
        case OAUTH_TOKEN_STATUS_ACTIVE:
            return t('oauthConnections:token-status-active');
        case OAUTH_TOKEN_STATUS_EXPIRED:
            return t('oauthConnections:token-status-expired');
    }
};

const getRefreshLabel = (t, status) => {
    switch (status) {
        case OAUTH_TOKEN_STATUS_UNKNOWN:
            return t('oauthConnections:token-label-refresh-unknown');
        case OAUTH_TOKEN_STATUS_ACTIVE:
            return t('oauthConnections:token-label-refresh-active');
        case OAUTH_TOKEN_STATUS_EXPIRED:
            return t('oauthConnections:token-label-refresh-expired');
    }
};

/* eslint-disable react/display-name */
export const createSelfConnectionStatusTypeField = (id, label, t, getConnectionId, refreshToken) => {
    return new DataField({
        id,
        label,
        render: (value) => {
            <SelfConnectionStatusTypeField
                label={getRefreshLabel(t, value)}
                value={getStatusLabel(t, value)}
            />;
        },
        renderColumn: (value, object) => {
            return (
                <SelfConnectionStatusTypeField
                    label={getRefreshLabel(t, value)}
                    value={getStatusLabel(t, value)}
                    object={object}
                    getConnectionId={getConnectionId}
                    refreshToken={refreshToken}
                    column
                />
            );
        },
        type: DataType.STRING,
    });
};
