import {createReducer} from '@automationanywhere/rio-components';

import * as actions from '../actions/generateAPI';
import {API_INITIAL_STATE, apiReducer} from '../../util/api';

const initialState = {
    apiKey: '',

    ...API_INITIAL_STATE,
};

export default createReducer(initialState, {
    ...apiReducer({
        name: actions.GENERATE_API,
        onDone(state, payload) {
            const {apiKey} = payload.result;
            return {
                apiKey,
            };
        },
    }),

    [actions.RESET_API]: () => ({...initialState}),
});
