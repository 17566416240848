/**
 * Creates a DataField that renders a string.
 *
 * @param {String} id - the attribute id.
 * @param {String} label - The label for the field.
 * @param {Function} t - the translate function.
 */

import {DataField, DataType} from '@automationanywhere/rio-components';

import {
    MIGRATIONS_TYPE_ROLE,
    MIGRATIONS_TYPE_BOT,
    MIGRATIONS_TYPE_AUDIT_DATA,
} from '../../../../store/constants/migrations';

const TYPE_LABELS = {
    [MIGRATIONS_TYPE_BOT]: 'migrations:type-bot',
    [MIGRATIONS_TYPE_ROLE]: 'migrations:type-role',
    [MIGRATIONS_TYPE_AUDIT_DATA]: 'migrations:type-audit-data',
};


export const createMigrationType = (id, label, t, options = {}) => {
    const renderString = (value) => {
        const label = TYPE_LABELS[value];
        return label ? t(label) : null;
    };

    return new DataField({
        id,
        label,
        renderString: (value) => renderString(value) || t('migrations:type-bot'),
        type: new DataType({
            name: 'migration-type',
            values: [MIGRATIONS_TYPE_BOT, MIGRATIONS_TYPE_ROLE, MIGRATIONS_TYPE_AUDIT_DATA],
        }),
        ...options,
    });
};
