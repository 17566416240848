
import React from 'react';
import {DataField, DataType} from '@automationanywhere/rio-components';

import ProviderTypeField from './ProviderTypeField.jsx';

/* eslint-disable react/display-name */
export const createProviderTypeDataField = (id, label, providers = [], options) => {
    return new DataField({
        id,
        label,
        render: (value) => {
            return (
                <ProviderTypeField
                    label={value}
                    value={value}
                />
            );
        },
        renderColumn: (value, connection) => {
            const provider = providers.find((provider) => provider.key === value);
            return (
                <ProviderTypeField
                    label={provider?.name}
                    value={value}
                    connection={connection}
                    column
                />
            );
        },
        type: DataType.STRING,
        ...options,
    });
};
