/**
 * Copyright 2018 Automation Anywhere.
 * All rights reserved.
 *
 * This software is the proprietary information of Automation Anywhere.
 * You shall use it only in accordance with the terms of the license agreement
 * you entered into with Automation Anywhere.
 */

export const CODE_TYPES = [
    'text/x-csrc', 'text/x-c++src', 'text/x-java', 'text/x-csharp', 'text/x-objectivec', 'text/x-scala', 'text/x-vertex', 'x-shader/x-fragment,',
    'text/x-clojure',
    'text/x-cobol',
    'text/x-common-lisp',
    'text/css', 'text/x-scss', 'text/x-less',
    'text/x-d',
    'application/dart',
    'text/x-dockerfile',
    'text/x-elm',
    'text/x-erlang',
    'text/x-fortran',
    'text/x-ocaml', 'text/x-fsharp',
    'text/x-go',
    'text/x-haskell',
    'text/javascript', 'application/json', 'application/ld+json', 'text/typescript', 'application/typescript',
    'text/jsx', 'text/typescript-jsx',
    'text/x-lua',
    'text/x-markdown',
    'text/x-mathematica',
    'text/x-pascal',
    'text/x-perl',
    'application/x-httpd-php', 'text/x-php',
    'application/x-powershell',
    'text/x-properties', 'text/x-ini',
    'text/x-protobuf',
    'text/x-python', 'text/x-cython',
    'text/x-q',
    'text/x-rsrc',
    'text/x-ruby',
    'text/x-rustsrc',
    'text/x-sass',
    'text/x-sh', 'application/x-sh',
    'text/x-stsrc',
    'text/x-sql', 'text/x-mysql', 'text/x-mariadb', 'text/x-cassandra', 'text/x-plsql', 'text/x-mssql', 'text/x-hive', 'text/x-pgsql', 'text/x-gql', 'text/x-gpsql', 'text/x-esper',
    'text/x-swift',
    'text/x-vb',
    'text/vbscript',
    'application/xml', 'text/html',
    'text/x-yaml',
];

export const CODE_TYPE_DEFAULT = 'text/text';
