import {connect} from 'react-redux';
import {createSelector} from 'reselect';
import {bindActionCreators} from 'redux';
import {prefixActions} from '@automationanywhere/rio-components';

import {routerPush} from '../../../../store/actions/router';
import * as auditActions from '../../../../store/actions/audit';
import {set as pageSet} from '../../../../store/actions/pages';
import {selectAuditObjects} from '../../../../store/selectors/audit';
import {selectT} from '../../../../store/selectors/i18n';

import AuditDetailsPageComponent from './AuditDetailsPage.jsx';

const selectAuditId = (state, props) => props.params.auditId;

const selectAuditDetails = createSelector(
    selectAuditObjects,
    selectAuditId,
    (auditById, auditId) => {
        const audit = auditById[auditId];
        if (audit && audit.detail) {
            try {
                return JSON.parse(audit.detail);
            }
            catch (error) {
                return null;
            }
        }
    }
);

const selectAudit = createSelector(
    selectAuditObjects,
    selectAuditId,
    (auditById, auditId) => auditById[auditId]
);

export const AuditDetailsPage = connect(
    (state, props) => ({
        t: selectT(state),
        audit: selectAudit(state, props),
        details: selectAuditDetails(state, props),
        auditDetailsPending: Boolean(state.audit.pending[`get-${props.params.auditId}`]),
        auditDetailsError: state.audit.error[`get-${props.params.auditId}`],
    }),
    (dispatch) => ({
        actions: bindActionCreators({
            ...prefixActions('audit', auditActions),
            setColumnSizes: (value) => pageSet('auditViewPage', 'columnSizes', value),
            routerPush,
        }, dispatch),
    })
)(AuditDetailsPageComponent);
