import React, {Component, createRef} from 'react';
import {autobind} from 'core-decorators';
import {createSelector} from 'reselect';
import moment from 'moment';
import {
    StaticTable,
    exportFileFromAnchor,
    DataTableHeader,
} from '@automationanywhere/rio-components';

import {
    MIGRATION_MANAGER_IMPLICIT_VALIDATIONS,
    MIGRATION_RUNNERS_IMPLICIT_VALIDATIONS,
} from '../../../../../../store/constants/migrations';

import './ValidationResult.scss';

export default class ValidationResult extends Component {
    static displayName = 'ValidationResult';

    constructor(props) {
        super(props);

        this.state = {
        };

        this.downloadRef = createRef();

        this.handlers = {};
        this.getHandler = (prefix, type, action, value) => {
            const key = `${prefix}-${type}-${action}-${value}`;
            let handler = this.handlers[key];
            if (!handler) {
                handler = () => {
                    this.setState({
                        [`${prefix}-${type}-${action}`]: value,
                    });
                };
                this.handlers[key] = handler;
            }
            return handler;
        };

        this.getValue = (prefix, type, action) => {
            return this.state[`${prefix}-${type}-${action}`] || false;
        };

        const ResultObject = function(type, userIds) {
            return {
                name: type,
                userIds,
            };
        };

        this.groupFailedValidations = createSelector(
            (props) => props.implicitValidations,
            (implicitValidations) => {
                const validations = [...MIGRATION_MANAGER_IMPLICIT_VALIDATIONS, ...MIGRATION_RUNNERS_IMPLICIT_VALIDATIONS];
                const failedValidationsMap = validations
                    .reduce((map, validationType) => {
                        const userIds = implicitValidations[validationType];
                        if (!map.has(validationType) && userIds.length > 0) {
                            map.set(validationType, new ResultObject(validationType, userIds));
                        }
                        return map;
                    }, new Map());
                return Array.from(failedValidationsMap, ([type, result]) => ({name: type, type: result}));
            }
        );

        this.selectToolbar = createSelector(
            (props) => props.t,
            (t) => [{
                label: t('action-export-label'),
                aa: 'action-export',
                onClick: this.handleExport,
            }]
        );
    }

    @autobind
    handleExport() {
        const failedValidations = this.groupFailedValidations(this.props);
        this.exportCSV(failedValidations);
    }

    @autobind
    exportCSV(data) {
        const {onExport} = this.props;
        const download = this.downloadRef.current;
        const filename = `ValidationResults-${moment().format('YYYYMMDD-HHmmss')}.csv`;

        const csvData = onExport(data);

        exportFileFromAnchor(download, filename, 'text/csv', csvData);
        download.setAttribute('download', filename);
        download.click();
    }

    renderFailure(failedValidations) {
        const {t, validationsTableFields} = this.props;

        return (
            <>
                <DataTableHeader
                    label={t('migrations:label-migration-issues')}
                    total={failedValidations.length}
                    toolbar={this.selectToolbar(this.props)}
                />
                <StaticTable>
                    <StaticTable.Header>
                        {validationsTableFields.map((field) => {
                            return (
                                <StaticTable.Column key={`header-column-${field.id}`} style={{width: '50%'}}>
                                    {field.label}
                                </StaticTable.Column>
                            );
                        })}
                    </StaticTable.Header>
                    {failedValidations.map((validation) => {
                        return (
                            <StaticTable.Row key={`row-${validation.name}`}>
                                {validationsTableFields.map((field) => {
                                    return (
                                        <StaticTable.Column key={`row-column-${field.id}`}>
                                            {field.renderColumn(validation[field.id])}
                                        </StaticTable.Column>
                                    );
                                })}
                            </StaticTable.Row>
                        );
                    })}
                </StaticTable>
            </>
        );
    }

    render() {
        const failedValidations = this.groupFailedValidations(this.props);
        return (
            <div className="validationresults">
                <a ref={this.downloadRef} style={{display: 'none'}}/>

                {this.renderFailure(failedValidations)}
            </div>
        );
    }
}
